import React from "react";
import PropTypes from 'prop-types';
import Button from "../../components/button/button";
import BackIcon from '../../images/BackIcon.inline.svg';
import {default as ReactSlider} from "react-slick";
import {defaultSettings} from "../../modules/slider/sliderSettings"
import {virtualCity__nav, virtualCity__nav__wrap, virtualCity__nav__back, reversed, defaultbtn} from './cityNav.module.scss';
import {BreakPtTablet} from '../../assets/sass/export.module.scss';

const VirtualCityNav = ({data, className, colorProfile, current, backBtn, navigate, ...other}) => {
  let navType = backBtn ? 'profile' : 'building';
  const bkptTabletInt = parseInt(BreakPtTablet);

  const settings = {
    ...defaultSettings,
    arrows: true,
    slidesToScroll: 1,
    slidesToShow: 7,
    infinite: data.length > 7 ? true : false,
    variableWidth: true,
    className: virtualCity__nav__wrap,
    responsive: [
      {
        breakpoint: bkptTabletInt,
        settings: {
          slidesToShow: 4,
          variableWidth: false,
          infinite: data.length > 4 ? true : false,
        }
      }
    ],
  };

  return (
      <nav key={navType} className={`${className} ${virtualCity__nav} ${colorProfile === 'Reversed' ? reversed : defaultbtn}`} {...other} >
        <div className={`container`}>
          {
            <Button onClick={()=>navigate({'type': 'back'})} className={`${virtualCity__nav__back} ${backBtn ? '' : 'tablet-max'}`} icon={false} ><BackIcon/>{backBtn && <span className={`helpText--right`}>Back to {backBtn}</span>}</Button>
          }
          {
            <ReactSlider {...settings} >
              {
                [...data].map((item, i)=>
                  <Button onClick={()=>item.displayTitle !== current ? navigate({'type': navType, [navType]: navType === 'building' ? item.slug : i, video: item.animation })  : false } className={`navBtn ${item.displayTitle === current ? 'active': ''}`} icon={false} key={i} >{item.displayTitle}</Button>
                )
              }
            </ReactSlider>
          }
        </div>
      </nav>

  )
}

export default VirtualCityNav;

VirtualCityNav.propTypes = {
  colorProfile: PropTypes.oneOf(['Default', 'Reversed'])
}

VirtualCityNav.defaultProps = {
  colorProfile: 'Default'
}