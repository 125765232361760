import React from "react";
import HtmlBlock from "../../../components/htmlBlock/htmlBlock";

const CaseStudyStat = ({data}) =>  
  data?.map((stat, i)=>{
  return <div key={i} className="stat">
            <HtmlBlock tag="span" data={stat.field1}/>
            {
              stat.field2 && <HtmlBlock data={stat.field2}/>
            }
        </div>
})

export default CaseStudyStat;
