import React, {useRef, useState, useEffect} from "react";
import Button from "../../../components/button/button";
import HtmlBlock from '../../../components/htmlBlock/htmlBlock';
import {virtualCityExpand, virtualCityExpand__btns,virtualCityExpand__btnprofile, virtualCityExpand__content, virtualCityExpand__btn, open} from './expandCard.module.scss';

const VirtualCityExpandCard = ({navBtns, children, className, heading, eyebrow, copy, profiles, updateProfile, style}) => {
const [isOpen, setIsOpen] = useState(false);
const [contentHeight, setcontentHeight] = useState(false);

const contentRef = useRef();

//Calculates the height of the content we want to hide
const getContentHeight = () => {
  // .h2 + card padding heights
  if(typeof document !== 'undefined'){
    let heightTotal = document.querySelector('.h2').offsetHeight + parseInt(window.getComputedStyle(contentRef?.current, null).getPropertyValue('padding-top'), 10);
    setcontentHeight(contentRef.current.offsetHeight - heightTotal);
  }
}

useEffect(() => {

  if(window !== 'undefined'){
    window.addEventListener("resize", getContentHeight);
  }
  return () => {
    window.removeEventListener("resize", getContentHeight);
  };
}, []);

useEffect(() => {
  getContentHeight();
});

return (
  <div className={`${virtualCityExpand} ${className || ''} virtualCityExpand`} ref={contentRef} style={{bottom: isOpen ? '0' : `-${contentHeight}px`, ...style}} >
      <Button onClick={()=>setIsOpen(!isOpen)} className={`${virtualCityExpand__btn} ${isOpen ? open : ''} tablet-max`} />
      {navBtns}
      {
        !profiles && children &&  
        <div className={`modalBtns ${virtualCityExpand__btnprofile}`}>
          {children}
        </div>
      }
      {
        profiles && children &&  
        <div className={`modalBtns ${virtualCityExpand__btns}`}>
          {children}
        </div>
      }
      <div className={`${virtualCityExpand__content} expandUpContent`} >
        {
          eyebrow && <HtmlBlock className={`h2`} tag={'span'}>{eyebrow}</HtmlBlock>
        }
        {
          heading && <HtmlBlock className={!eyebrow ? `h2` : 'h4'} tag={'h1'}>{heading}</HtmlBlock>
        }
        {
          copy && <HtmlBlock tag={`p`} data={copy} />
        }
        {
          profiles?.map((profile, i)=><Button onClick={()=>updateProfile({type: 'profile', profile: i, video: profile.animateIn[0] })} key={i} className={`link--text--tertiary`}>{profile.displayTitle}</Button>)
        }
      </div>
 

  </div>
  )
}

export default VirtualCityExpandCard
