import React, {useState, useEffect, useContext} from "react";
import { graphql } from "gatsby";
import Button from "../../../components/button/button";
import ModalTrigger from "../../../components/modal/modalTrigger/modalTrigger";
import GearIcon from '../../../images/GearIcon.inline.svg';
import VirtualCityExpandCard from "../expandCard/expandCard";
import {virtualCity__profile, virtualCity__nav} from './profile.module.scss';
import { LangContext } from '../../../context/LocaleContext';
import Smart_Node from '../../../images/Smart_Node.inline.svg';
import Software_icon from "../../../images/Software_icon.inline.svg";
import Play_Button from "../../../images/Play_Button.inline.svg";



const VirtualCityProfile = ({currentProfile, lastProfile, nextProfile, updateProfile, ...other}) => {
  const [currentDetailIndex, setCurrentDetailIndex] = useState(0);
  let currentData = currentProfile?.detailPages[currentDetailIndex];
  let { copy, heading, featuredDevice,wistiaId1,featuredSoftware, featuredDeviceContent,featuredSoftwareSubheading,featuredSoftwareContent, featuredDeviceSubheading, transitionForwardStartEnd, transitionBackwardStartEnd } = currentData || {};
  let isFirst = currentDetailIndex === 0;
  let isLast = currentDetailIndex === currentProfile?.detailPages.length - 1;
  const { getTranslation } = useContext(LangContext);

  useEffect(()=>{
    setCurrentDetailIndex(0);
  }, [currentProfile])

  const navigate = (dir) => {
    if(dir === 'back' && transitionBackwardStartEnd){
      if(isFirst){
        //use nav to go back a profile
        updateProfile({type: 'profile', profile: lastProfile.index, video: transitionBackwardStartEnd[0]});
      }else{
        setCurrentDetailIndex( currentDetailIndex - 1 );
        updateProfile({type: 'profile', video: transitionBackwardStartEnd[0]});
      }
    }else if(dir === 'fwd' && transitionForwardStartEnd){
      if(isLast){
        updateProfile({type: 'profile',profile: nextProfile.index,  video: transitionForwardStartEnd[0]});
      }else{
        setCurrentDetailIndex( currentDetailIndex + 1);
        updateProfile({type: 'profile', video: transitionForwardStartEnd[0]});

      }
    }
  }

  const NavBtns = ({helpText, className, dashes}) => {
    return (
      <nav className={`${className} ${virtualCity__nav}`} >
          <Button className={'btn--circle--dark--small btn--back'} onClick={()=>navigate('back')}>
            {(isFirst && helpText) && <span className={`helpText--left`}>{lastProfile.heading}</span>}
          </Button>
          {
            dashes && currentProfile?.detailPages.map((detail, i)=><span className={`dash`} aria-current={heading === detail.heading ? 'step': false} key={i} />)
          }
          <Button className={'btn--circle--dark--small'} onClick={()=>navigate('fwd')} >
            {(isLast && helpText) && <span className={`helpText--right`}>{nextProfile.heading}</span>}
          </Button>
      </nav>
    )
  }

  return (
    <>
      <NavBtns helpText={false} dashes={false} className={'tablet-max'} />
      <VirtualCityExpandCard navBtns={<NavBtns dashes={true} helpText={false} className={'desktop--small-min'} />} className={virtualCity__profile} heading={heading} copy={copy} eyebrow={currentProfile?.displayTitle} {...other} >
      {
  (featuredDevice !== null && featuredSoftware !== null) && (
    <>
    <ModalTrigger
      icon={false}
      className={`tabButton`}
      id={'productModal'}
      data={{
        type: 'product',
        data: {
          image: featuredDevice?.seo?.image,
          heading: featuredDevice?.displayTitle,
          subheading: featuredDeviceSubheading,
          linkS: [{ displayText: getTranslation("DeviceDetails"), slug: featuredDevice.slug }],
          content: featuredDeviceContent,
        },
      }}
      modalClasses={`fullScreen--default`}
    >
      <span className={`helpText--top`}>{getTranslation("Products")}</span>
      <Smart_Node />
    </ModalTrigger>
      <ModalTrigger
        icon={false}
        className={`tabButton`}
        id={'productModal'}
        data={{
          type: 'product',
          data: {
            image: featuredSoftware?.seo?.image,
            heading: featuredSoftware?.title,
            subheading: featuredSoftwareSubheading,
            linkS: [{ displayText: getTranslation("Software Details"), slug: featuredSoftware.slug }],
            content: featuredSoftwareContent,
          },
        }}
        modalClasses={`fullScreen--default`}
      >
        <span className={`helpText--top`}>{getTranslation("Software")}</span>
        <Software_icon />
      </ModalTrigger>
      </>
    )
}
    {featuredDevice !== null && featuredSoftware === null && (
          <ModalTrigger
          icon={false}
          className={`tabButton`}
          id={'productModal'}
          data={{
            type: 'product',
            data: {
              image: featuredDevice?.seo?.image,
              heading: featuredDevice?.displayTitle,
              subheading: featuredDeviceSubheading,
              linkS: [{ displayText: getTranslation("DeviceDetails"), slug: featuredDevice.slug }],
              content: featuredDeviceContent,
            },
          }}
          modalClasses={`fullScreen--default`}
        >
          <span className={`helpText--top`}>{getTranslation("Products")}</span>
          <Smart_Node />
        </ModalTrigger>
        )}
            {featuredSoftware !== null && featuredDevice === null && (
      <ModalTrigger
      icon={false}
      className={`tabButton`}
      id={'productModal'}
      data={{
        type: 'product',
        data: {
          image: featuredSoftware?.seo?.image,
          heading: featuredSoftware?.title,
          subheading: featuredSoftwareSubheading,
          linkS: [{ displayText: getTranslation("Software Details"), slug: featuredSoftware.slug }],
          content: featuredSoftwareContent,
        },
      }}
      modalClasses={`fullScreen--default`}
    >
      <span className={`helpText--top`}>{getTranslation("Software")}</span>
      <Software_icon />
    </ModalTrigger>
        )}

    { 
        (wistiaId1 !== '' && wistiaId1 !== undefined && wistiaId1 !== null) && ( <ModalTrigger icon={false} className={`tabButton`} id={'VideoModal'} data={{ type: 'Video',Video:wistiaId1 }} modalClasses={`fullScreen--default`}> <span className={`helpText--left`}>{getTranslation("Video")}</span><Play_Button/> </ModalTrigger>)
    }
      </VirtualCityExpandCard>
    </>
  
  )
}

export default VirtualCityProfile

export const ProfileFragment = graphql`
  fragment ProfileFragment on DatoCmsVirtualCityProfile {
    detailPages{
      copy
      featuredDevice{
        seo{
          image{
            gatsbyImageData(width: 730)
            url
          }
        }
        slug
        displayTitle
  
      }
      featuredSoftware{
        seo{
          image{
            gatsbyImageData(width: 630)
            url
          }
        }
        slug
        title
        
  
      }
      wistiaId1
      featuredDeviceContent
      featuredDeviceSubheading
      featuredSoftwareContent
      featuredSoftwareSubheading
      heading
      transitionForwardStartEnd{
        ...MultiFieldFragment
      }
      transitionBackwardStartEnd{
        ...MultiFieldFragment
      }
    }
    displayTitle
    animateIn{
      ...MultiFieldFragment
    }
}`

// featuredDeviceContent add this back
