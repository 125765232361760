import React, { useReducer, useContext } from 'react';
import { graphql, navigate } from 'gatsby';

import Building from '../virtualCity/building/building';
import Button from '../components/button/button';
import ContentBlock from '../components/contentBlock/contentBlock';
import HtmlBlock from '../components/htmlBlock/htmlBlock';
import Image from '../components/image/image';
import Loading from '../components/loading/loading';
import VirtualCityNav from '../virtualCity/cityNav/cityNav';
import Wistia from '../components/wistiaVideo/wistiaVideo';
import { LangContext } from '../context/LocaleContext';

import {virtualCity, virtualCity__intro, virtualCity__warning} from '../virtualCity/virtualCity.module.scss';


/**
 * Virtual City
 */    
const VirtualCity = ({data, location}) => {
  const { getTranslation } = useContext(LangContext);
  const {buildings, cityImage, introHeading, screenTooSmallWarning } = data?.datoCmsPageVirtualCity || {};

  const buildingParam = 'building';
  const profileParam = 'profile';

  const params = new URLSearchParams(location.search);
  const buildingSlug = params.get(buildingParam);
  const profileSlug = params.get(profileParam);

  /**
   * Checks if value is empty (returns false if value is 0)
   */ 
  function isEmpty(value){
    return (value == null || value.length === 0);
  }

   /**
   * Simplifies MultiFieldFragment object into an array of numbers
   * always returns an array with length 2
   * if video is not an object, assume it starts at 0
   * 
   */ 
  const videoArray = (video) => {
    return typeof video === 'object' ? [parseInt(video.field1), parseInt(video.field2)] : [0, parseInt(video)];
  }

  const videoReducer = (state, action) => {
    return {
      ...state,
      [action.id] : action.status
    }
  }

    /**
     * Reducer to handle all page states
     */ 
     const cityReducer = (state, action) => {
      let newState={};
      switch(action.type){
        case 'back':
        if(!isEmpty(state.profile)){
          // console.log('moving back from profile');
          updateUrl(profileParam);
          newState.profile = null;
          newState.video = [(parseInt(state.building.animateInEnd) - .25) > 0 ? (parseInt(state.building.animateInEnd) - .25) : 1, parseInt(state.building.animateInEnd)];
        }else if(state.building){
          // console.log('moving back from building');
          updateUrl(buildingParam);
          newState.nextBuilding = 'intro';
          newState.video = videoArray(state.building.animateOutStartEnd[0]);
        }

        return{
          ...state,
          ...newState
        }
        case 'building':
        if( action.building){
          updateUrl(buildingParam, action.building);
        }
        let newBuilding = action.building ? buildings.find(building => building.slug === action.building) : null;
        //Moving in from start screen
        if(isEmpty(state.building)){
          // console.log('Moving from start page');
          newState.building = newBuilding;
          newState.video = videoArray(newBuilding.animateInEnd);
        }      
        else{
          //Animate out from building
          // console.log('Moving out of building');
          newState.nextBuilding = newBuilding;
          newState.video = videoArray(state.building.animateOutStartEnd[0]);
        }

        return{
          ...state,
          ...newState
        }

        //handles all updates in the profile file
        case 'profile': 
        // console.log('profile change');
        if(!isEmpty(action.profile)){
          updateUrl(profileParam, action.profile);
        }
        return{
          ...state,
          profile: !isEmpty(action.profile) ? action.profile : state.profile ,
          video: videoArray(action.video)
        }

        case 'switchVideo':
          if(state.nextBuilding){
            if(state.nextBuilding !== 'intro'){
              // console.log('video switched now resetting and animating into building', state.nextBuilding.wistiaId, videoArray(state.nextBuilding.animateInEnd));
              updateUrl(buildingParam,  state.nextBuilding.slug);
            }
            return{
              building : state.nextBuilding === 'intro' ? null : state.nextBuilding,
              video : videoArray(state.nextBuilding.animateInEnd),
              nextBuilding : undefined
            }
          }
          break

        default: 
          console.warn('no action type provided in city reducer')
          break;
      }
      return state;
    }

  /**
   * updates parameters & navigates
   * Will remove param if it's value is empty
   */ 
    const updateUrl = (param, val) =>{
      if(!isEmpty(val)){
        params.set(param, val);
      }else{
        params.delete(param);
      }
      var newRelativePathQuery = window?.location?.pathname + '?' + params.toString();
      navigate(newRelativePathQuery);
    }

  /**
   *  Initial Reducer state (pulls from url params)
   */ 
  const init = (buildingSlug, profileParam) => {
    let initialBuilding = buildingSlug ? buildings.find(building => building.slug === buildingSlug) : null;
    let initialProfile = (profileParam && initialBuilding) ? profileParam : null;
    return {
      building: initialBuilding,
      profile: !isEmpty(initialProfile) ? parseInt(initialProfile) : null,
      video: !isEmpty(initialProfile) ? videoArray(initialBuilding.profiles[initialProfile].animateIn[0]) : videoArray(initialBuilding?.animateInEnd),
    }
  }


  const [videoState, videoDispatch] = useReducer(videoReducer, null, ()=>
      buildings && buildings.map((building)=>building.wistiaId).reduce((accumulator, value) => {
        return {...accumulator, [value]: 'unmounted'};
      }, {})
    );

  let isLoaded = videoState && Object.values(videoState).indexOf('unmounted') === -1;

  const [cityState, cityDispatch] = useReducer(cityReducer, null, ()=>init(buildingSlug, profileSlug));

  const { building, profile, video} = cityState;

   /**
   *  Returns an array with only the required object (properties) for VirtualCityNavigation component
   */ 
  const getNavArray = (arrayIn) => {
    return arrayIn && arrayIn.map((item)=>{ return {'animation' : item?.animateIn ? item?.animateIn[0] : null, 'slug' : item.slug || item.displayTitle , 'displayTitle' : item.displayTitle} } )
  }


  return (
    <div className={virtualCity}>
        <Image data={cityImage} className="backgroundMedia"/>
        {
          videoState && Object.keys(videoState).map((id, key) => {
            let isCurrent = id === building?.wistiaId && isLoaded;
            return <Wistia isCurrent={isCurrent} className={`backgroundMedia`} id={id} key={key} style={{opacity: isCurrent ? '1' : '0'}} videoSegment={isCurrent ? video : [0, 0]}  videoType="City" controls={false} passVideoState={videoDispatch} videoCallBack={cityDispatch} /> 

          }
          )
        }
        {
        !isLoaded ?
        <div className={virtualCity__intro} >
          { introHeading && <HtmlBlock tag={'h1'}>{getTranslation('Loading')}...</HtmlBlock>}
          <Loading className="loader--dots--white" /> 
        </div>
        :
        <>
        <div className={`tablet-min`}>
          {
            building && 
              <>
                <VirtualCityNav style={{opacity: videoState[building.wistiaId] === 'paused' ? 1 : 0 }} navigate={cityDispatch} backBtn={isEmpty(profile) ? false : building?.displayTitle } current={ (building?.profiles && building?.profiles[profile]?.displayTitle) || building?.displayTitle } data={isEmpty(profile) ? getNavArray(buildings) : getNavArray(building.profiles) } />
              </>
          }
          {
            building ?
              <Building data={building} updateProfile={cityDispatch} videoState={ videoState[building.wistiaId] } profile={profile}  />
            :
            <div className={virtualCity__intro}>
              { introHeading && <HtmlBlock tag={'h1'}>{introHeading}</HtmlBlock>}
              <VirtualCityNav navigate={cityDispatch} colorProfile="Reversed" data={getNavArray(buildings)} />
              <Button onClick={()=>cityDispatch({type: 'building', building: buildings[0].slug})} className={`btn--primary--inverse`} >{getTranslation('JumpIn')}</Button>
            </div>
          }
        </div>
        <div className={`${virtualCity__warning} mobile`}>
          <ContentBlock headingTag={'h1'} data={screenTooSmallWarning[0]}/>
        </div>
      </>
      }
    </div>
  )
}

export default VirtualCity

export const query = graphql`
  query($locale: String) {
    datoCmsPageVirtualCity(locale : {eq:$locale}) {
      buildings{
        ... BuildingFragment
      }
      cityImage{
        ...BackgroundImageFragment
      }
      introHeading
      model{
        name
      }
      screenTooSmallWarning{
        ...HeadingCopyLinkFragment
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
    }
  }
`