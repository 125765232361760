// extracted by mini-css-extract-plugin
export var absoluteSubmit = "expandCard-module--absoluteSubmit--NOCYu";
export var actions = "expandCard-module--actions--jPiuN";
export var chevron = "expandCard-module--chevron--B9mRk";
export var focusVisible = "expandCard-module--focus-visible--+Xojf";
export var hsErrorMsg = "expandCard-module--hs-error-msg--gegBi";
export var hs_submit = "expandCard-module--hs_submit--2D0h2";
export var icon = "expandCard-module--icon--jGcsB";
export var input = "expandCard-module--input--TD4FE";
export var open = "expandCard-module--open--+V71W";
export var virtualCityExpand = "expandCard-module--virtualCityExpand--5rxQB";
export var virtualCityExpand__btn = "expandCard-module--virtualCityExpand__btn--rWf4i";
export var virtualCityExpand__btnprofile = "expandCard-module--virtualCityExpand__btnprofile--ss2SR";
export var virtualCityExpand__btns = "expandCard-module--virtualCityExpand__btns--bxVns";
export var virtualCityExpand__content = "expandCard-module--virtualCityExpand__content--jYnZ5";