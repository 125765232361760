import React from "react";
import Image from "../../components/image/image";
import ContentBlock from "../../components/contentBlock/contentBlock";
import Quote from "../../components/quote/quote";
import { virtualCityPopup, virtualCityPopup__featuredImage,virtualCityPopup__ImageTab, virtualCityPopup__stats } from './popup.module.scss';
import CaseStudyStat from "../../modules/stats/statBlocks/statBlockCaseStudy";
import Wistia from "../../components/wistiaVideo/wistiaVideo";
import PropTypes from 'prop-types';

const VirtualCityPopup = ({ data }) => {
  const { type, featuredImage,featuredImage1, stats,Video, data: typeData } = data || {};
  return (
    <div className={virtualCityPopup}>
      <Image className={virtualCityPopup__featuredImage} data={featuredImage} />
      <Image className={virtualCityPopup__ImageTab} data={featuredImage1} />
      <div className={`${type}`}>
        {type === 'product' && 
          <ContentBlock data={typeData} linkclass={`btn--tertiary`} />
        }
        {type === 'testimonial' && 
          <div>
            <Quote data={typeData} icons={true} iconOutline={true} />
          </div>
        }
        {type === 'caseStudy' && 
          <div>
            <div className={virtualCityPopup__stats}>
              <CaseStudyStat data={stats} />
            </div>
            <ContentBlock data={typeData} linkclass={`btn--primary`} />
          </div>
        }
        {type === 'Video' && 
          <Wistia id={Video} quality="1080" controls={false} videoType="Single" />
        }
        {type === 'Images' && 
          <div>
            <div className={virtualCityPopup__stats}>
              <CaseStudyStat data={stats} />
            </div>
            <ContentBlock data={typeData} linkclass={`btn--primary`} />
          </div>
        }
      </div>
    </div>
  );
}

export default VirtualCityPopup;


