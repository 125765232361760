import React, {useContext} from "react";
import { graphql } from "gatsby";
import BackIcon from '../../images/BackIcon.inline.svg';
import Button from "../../components/button/button";
import ModalTrigger from "../../components/modal/modalTrigger/modalTrigger";
import TestimonialIcon from '../../images/TestimonialIcon.inline.svg';
import CaseStudyIcon from '../../images/caseStudyIcon.inline.svg';
import Profile from './profile/profile';
import VirtualCityExpandCard from "./expandCard/expandCard";
import {virtualCity__building} from './building.module.scss';
import { LangContext } from '../../context/LocaleContext';
import Wistia from '../../components/wistiaVideo/wistiaVideo';
import VirtualCityPopup from "../popup/popup";
import Play_Button from "../../images/Play_Button.inline.svg";
import Image_Icon from "../../images/Image_Icon.inline.svg";

const VirtualCityBuilding = ({data, updateProfile, profile, videoState}) => {
const {caseStudy, caseStudyImageOverride, description, displayTitle, image,popupImage , profiles,quote,wistiaId1, author} = data || {};
const { getTranslation } = useContext(LangContext);

return (
  <>
    {
      (profile !== undefined & profile !== null) ? 
      <Profile 
        style={{opacity: videoState === 'paused' ? '1' : '0'}} 
        updateProfile={updateProfile} currentProfile={profiles[profile]} 
        lastProfile={{
          'index': 0 <= profile - 1 ? profile-1 : profiles.length -1, 
          'heading' : profiles[0 <= profile - 1 ? profile - 1 : profiles.length - 1 ].displayTitle
        }} 
        nextProfile={{
          'index' : profiles.length > profile + 1 ? profile + 1 : 0, 
          'heading': profiles[profiles.length > profile + 1 ? profile + 1 : 0].displayTitle
        }} 
      >
      </Profile>
      :
      <VirtualCityExpandCard 
        style={{opacity: videoState === 'paused' ? '1' : '0'}}
        className={virtualCity__building} 
        heading={displayTitle}  
        copy={description} 
        profiles={profiles}
        updateProfile={updateProfile}
      >
        {
          <Button onClick={()=>updateProfile({type: 'profile', profile: 0, video: profiles[0].animateIn[0] })} className={`tablet-max tabButton mirror`}><BackIcon /></Button>
        }
        {
          caseStudy && <ModalTrigger data={{type: 'caseStudy', featuredImage: caseStudy.featuredImage || caseStudyImageOverride, stats: caseStudy.stats, data: { linkS: [{displayText: getTranslation('FullCaseStudy'), slug: caseStudy.slug ,target:"_blank"}], content: description } }} modalClasses={`fullScreen--default`} icon={false} id={`caseStudyModal`} className={`tabButton`} ><span className={`helpText--left`}>{getTranslation('CaseStudies')}</span><CaseStudyIcon/></ModalTrigger>
        }
        {
          quote && <ModalTrigger data={{type: 'testimonial', featuredImage: image, data: {quote : quote, author: author} }} modalClasses={`fullScreen--reversed`} icon={false} id={`testimonialModal`} className={`tabButton`}><span className={`helpText--left`}>{getTranslation('Testimonials')}</span><TestimonialIcon/></ModalTrigger>
        }
        { 
          (wistiaId1 !== '' && wistiaId1 !== undefined && wistiaId1 !== null) && ( <ModalTrigger icon={false} className={`tabButton`} id={'VideoModal'} data={{ type: 'Video',Video:wistiaId1 }} modalClasses={`fullScreen--default`}> <span className={`helpText--left`}>{getTranslation("Video")}</span><Play_Button/> </ModalTrigger>)
        }
        {
          popupImage != null && <ModalTrigger data={{type: 'Images', featuredImage1: popupImage, data: {quote : quote, author: author} }} modalClasses={`fullScreen--reversed`} icon={false} id={`ImageModal`} className={`tabButton`}><span className={`helpText--left`}>{getTranslation('Image')}</span><Image_Icon/></ModalTrigger>
        }
      </VirtualCityExpandCard>
    }
    <VirtualCityPopup data={wistiaId1} />
  </>
  )
}

export default VirtualCityBuilding;

export const BuildingFragment = graphql`
  fragment BuildingFragment on DatoCmsVirtualCityBuilding {   
    animateInEnd
    animateOutStartEnd{
      ...MultiFieldFragment
    }
    caseStudy{
      title
      stats{
        ...MultiFieldFragment
      }
      featuredImage{
        ...HalfImageFragment
      }
      slug
    }
    caseStudyImageOverride{
      ...HalfImageFragment
    }
    description
    profiles{
      ...ProfileFragment
    }
    slug
    displayTitle
    wistiaId
    wistiaId1
    image{
      ...HalfImageFragment
    }
    popupImage{
      ...HalfImageFragment
    }
    quote
    author
}`;

export const MultiFieldFragment = graphql`
  fragment MultiFieldFragment on DatoCmsComponentFieldPair{
    field1
    field2
  }
`;
