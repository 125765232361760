import React from 'react';
import Button from '../../components/button/button';

const SlickArrow = ({ currentSlide, children, className, slideCount, ...props }) => (
  <div className="container slick-arrow__wrapper">
  <Button
      {...props}
      className={
      className}
    >
      {children}
    </Button>
  </div>
 
);

export const defaultSettings = {
    accessibility: false,
    onInit: () => {
      //adds aria-current to first dot and nav item
      const firstDot = document.querySelectorAll('.slick-active button')[0];
      const dots = document.querySelectorAll('.slick-dots li button');
      firstDot?.setAttribute('aria-current', "true");
      Array.from(dots).forEach((dot, i) => {
        dot.setAttribute('aria-label', i + 1);
      });
    },
    afterChange: index => {
      const dots = document.querySelectorAll('.slick-dots li button');
      if(dots){
        Array.from(dots).forEach(dot => {
          if(dot.parentNode.classList.contains("slick-active")) {
            dot.setAttribute('aria-current', "true");
          } else {
            dot.setAttribute('aria-current', "false");
          }
        });
      };
    },
    nextArrow: <SlickArrow className="btn--circle--dark slick-prev slick-arrow" ><div className="sr_only">Next</div></SlickArrow>,
    prevArrow: <SlickArrow className="btn--circle--dark slick-next slick-arrow"><div className="sr_only">Previous</div></SlickArrow>
}