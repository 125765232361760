import * as React from "react";
import HtmlBlock from "../htmlBlock/htmlBlock";
import OpenQuote from "../../images/CloseQuotes.inline.svg";
import CloseQuote from "../../images/OpenQuote.inline.svg";

const Quote = ({data, icons, iconOutline}) => {
    const {author, quote} = data || {};
    return (
      <>
        {
          icons && 
          <CloseQuote />
        }
        <blockquote>
          <HtmlBlock tag={'p'} data={quote}/>
          { author &&
            <footer>{author}</footer>
          }
        </blockquote>
        {
          icons && 
          (
            iconOutline ?
            <CloseQuote style={{transform: 'rotate(180deg)'}} />
            :
            <OpenQuote />
          )
        }
      </>
    )
}

export default Quote;
